export const currencies = {
  EUR: "Euro",
  USD: "United States Dollar",
  JPY: "Japanese Yen",
  BGN: "Bulgarian Lev",
  CZK: "Czech Koruna",
  DKK: "Danish Krone",
  GBP: "British Pound Sterling",
  HUF: "Hungarian Forint",
  PLN: "Polish Złoty",
  RON: "Romanian Leu",
  SEK: "Swedish Krona",
  CHF: "Swiss Franc",
  ISK: "Icelandic Króna",
  NOK: "Norwegian Krone",
  HRK: "Croatian Kuna",
  RUB: "Russian Ruble",
  TRY: "Turkish Lira",
  AUD: "Australian Dollar",
  BRL: "Brazilian Real",
  CAD: "Canadian Dollar",
  CNY: "Chinese Yuan",
  HKD: "Hong Kong Dollar",
  IDR: "Indonesian Rupiah",
  ILS: "Israeli New Shekel",
  INR: "Indian Rupee",
  KRW: "South Korean Won",
  MXN: "Mexican Peso",
  MYR: "Malaysian Ringgit",
  NZD: "New Zealand Dollar",
  PHP: "Philippine Peso",
  SGD: "Singapore Dollar",
  THB: "Thai Baht",
  ZAR: "South African Rand",
};
export const currencyDescriptions = {
  EUR: "The Euro (€) symbolizes European integration and economic strength, used by 20 of the 27 European Union (EU) member states. Established in 1999, it streamlines cross-border transactions, reducing exchange rate risks. As the world's second-most held reserve currency, it boosts trade, lowers transaction costs, enhances price transparency, and attracts foreign investments. Challenges like economic disparities persist, but ongoing integration efforts suggest a promising future for the Euro. It remains a symbol of unity, playing a vital role in Europe's economic stability and global prominence.",
  USD: "The United States Dollar (USD) is the world’s primary reserve currency, widely used in international trade and finance. Renowned for its stability and liquidity, the USD is a benchmark for global economic transactions. It holds a central role in the global financial system, serving as the preferred currency for international reserves, commodities, and trade agreements. The strength of the U.S. economy significantly influences the value of the USD, making it a crucial indicator in global financial markets.",
  JPY: "The Japanese Yen (JPY) is Japan’s official currency, renowned for its stability. The Yen is a key player in the foreign exchange market and is considered a safe-haven currency. Japan's economic strength, technological innovation, and global trade contribute to the Yen’s status as a major international currency. The Bank of Japan plays a pivotal role in managing the Yen's value and ensuring economic stability within the country.",
  BGN: "The Bulgarian Lev (BGN) is Bulgaria’s official currency and has been part of the European Union since 2007. As a member of the EU, the Lev is integral to Bulgaria’s economic interactions within the European market. Bulgaria's economic development, coupled with its strategic location in Southeast Europe, contributes to the Lev's role as a key currency in the region.",
  CZK: "The Czech Koruna (CZK) is the official currency of the Czech Republic. As a central European nation with a diverse and export-oriented economy, the Koruna reflects the economic strength and stability of the Czech Republic. The currency is managed by the Czech National Bank, which aims to ensure price stability and support sustainable economic growth.",
  DKK: "The Danish Krone (DKK) is Denmark’s currency, known for its economic stability. Denmark’s well-developed welfare state, strong governance, and sound economic policies contribute to the stability of the Krone. The currency is managed by Danmarks Nationalbank, which focuses on maintaining price stability and supporting the overall economic well-being of the country.",
  GBP: "The British Pound Sterling (GBP) is the currency of the United Kingdom. With a rich history, the Pound has been a significant global currency, and its influence extends beyond the UK. The Bank of England oversees monetary policy, managing the Pound to ensure economic stability. The GBP's value is impacted by economic indicators, fiscal policies, and global economic trends.",
  HUF: "The Hungarian Forint (HUF) is Hungary’s currency and has been part of the European Union since 2004. As a member of the EU, the Forint plays a role in Hungary’s economic integration and trade relationships. The National Bank of Hungary manages the Forint, aiming to maintain price stability and support sustainable economic growth within the country.",
  PLN: "The Polish Złoty (PLN) is the official currency of Poland, reflecting the country’s dynamic and growing economy. As a member of the European Union, the Złoty is integral to Poland’s economic interactions within the EU market. The National Bank of Poland focuses on maintaining price stability and supporting the overall economic well-being of the country.",
  RON: "The Romanian Leu (RON) is Romania’s official currency and has been part of the European Union since 2007. As a member of the EU, the Leu is essential to Romania’s economic integration and trade relationships. The National Bank of Romania plays a crucial role in managing the Leu and ensuring economic stability within the country.",
  SEK: "The Swedish Krona (SEK) is Sweden’s currency, reflecting the country’s strong and stable economy. Sweden's commitment to social welfare, innovation, and a well-functioning market economy contributes to the Krona's stability. The currency is managed by Sveriges Riksbank, which focuses on maintaining price stability and supporting sustainable economic growth.",
  CHF: "The Swiss Franc (CHF) is Switzerland’s official currency, renowned for its stability. Switzerland's status as a global financial hub and its reputation for financial integrity contribute to the Franc's role as a safe-haven currency. The Swiss National Bank manages the Franc, with a focus on ensuring price stability and supporting the overall economic well-being of the country.",
  ISK: "The Icelandic Króna (ISK) is the official currency of Iceland. As a small but resilient economy, Iceland’s unique economic landscape influences the Króna's value. The Central Bank of Iceland manages the Króna, aiming to ensure financial stability and sustainable economic growth.",
  NOK: "The Norwegian Krone (NOK) is Norway’s currency, tied to its status as an oil-rich nation. Norway's wealth from oil and gas resources contributes to the Krone's stability. The currency is managed by Norges Bank, which focuses on maintaining price stability and supporting the overall economic well-being of the country.",
  HRK: "The Croatian Kuna (HRK) is Croatia’s official currency, known for its unique charm and economic resilience. As a nation with a beautiful coastline and diverse tourism industry, the Kuna reflects Croatia’s economic identity. The Croatian National Bank manages the Kuna, aiming to ensure financial stability and support economic growth.",
  RUB: "The Russian Ruble (RUB) is the official currency of Russia, a Eurasian economic powerhouse. The Ruble is influenced by Russia's vast natural resources, geopolitical factors, and economic policies. The Central Bank of Russia plays a crucial role in managing the Ruble and ensuring financial stability within the country.",
  TRY: "The Turkish Lira (TRY) is Turkey’s official currency, situated at the crossroads of Europe and Asia. The Lira reflects Turkey’s economic vibrancy, diverse culture, and strategic geopolitical position. The Central Bank of the Republic of Turkey manages the Lira, aiming to ensure price stability and support sustainable economic growth.",
  AUD: "The Australian Dollar (AUD) is the official currency of Australia, known for its stability in the Asia-Pacific region. Australia's resource-rich economy, stable political environment, and close ties to Asia contribute to the Dollar's strength. The Reserve Bank of Australia manages the currency, focusing on maintaining price stability and supporting overall economic well-being.",
  BRL: "The Brazilian Real (BRL) is the official currency of Brazil, representing the largest economy in South America. The Real is influenced by Brazil's diverse economic sectors, including agriculture, mining, and manufacturing. The Central Bank of Brazil plays a crucial role in managing the Real and ensuring financial stability within the country.",
  CAD: "The Canadian Dollar (CAD) is the official currency of Canada, reflecting its stable and diverse economy. Canada's resource-rich landscape, including significant oil and natural gas reserves, contributes to the Dollar's strength. The Bank of Canada manages the currency, aiming to ensure price stability and support overall economic well-being.",
  CNY: "The Chinese Yuan (CNY) is the official currency of China, a major player in the global economy. The Yuan reflects China's economic growth, manufacturing prowess, and influence in international trade. The People’s Bank of China manages the Yuan, with a focus on maintaining financial stability and supporting sustainable economic development.",
  HKD: "The Hong Kong Dollar (HKD) is used in the global financial hub of Hong Kong. As an international financial center, the Dollar plays a crucial role in Hong Kong’s economic activities. The Hong Kong Monetary Authority manages the currency, focusing on maintaining the stability of the Hong Kong Dollar pegged to the U.S. Dollar.",
  IDR: "The Indonesian Rupiah (IDR) is the official currency of Indonesia, Southeast Asia’s largest economy. The Rupiah reflects Indonesia’s economic growth, diverse culture, and regional importance. Bank Indonesia manages the Rupiah, aiming to ensure financial stability and support economic development.",
  ILS: "The Israeli New Shekel (ILS) is the official currency of Israel, reflecting its dynamic economy in the Middle East. Israel's technological innovation, strong defense sector, and diverse industries contribute to the Shekel's strength. The Bank of Israel manages the currency, focusing on maintaining price stability and supporting overall economic well-being.",
  INR: "The Indian Rupee (INR) is the official currency of India, a rapidly growing economy. The Rupee reflects India’s economic progress, cultural diversity, and its role in the global market. The Reserve Bank of India manages the Rupee, focusing on maintaining price stability and supporting sustainable economic growth.",
  KRW: "The South Korean Won (KRW) is the official currency of South Korea, a leading technological and industrial hub. The Won reflects South Korea’s economic strength, innovation, and global influence. The Bank of Korea manages the currency, focusing on maintaining price stability and supporting overall economic well-being.",
  MXN: "The Mexican Peso (MXN) is the official currency of Mexico, with strong ties to the U.S. economy. The Peso is influenced by Mexico's manufacturing sector, agricultural production, and remittances. The Bank of Mexico manages the currency, aiming to ensure financial stability and support economic growth.",
  MYR: "The Malaysian Ringgit (MYR) is the official currency of Malaysia, indicative of its diverse economy. Malaysia's economic activities in manufacturing, electronics, and natural resources contribute to the Ringgit's strength. Bank Negara Malaysia manages the currency, focusing on maintaining price stability and supporting overall economic well-being.",
  NZD: "The New Zealand Dollar (NZD) is the official currency of New Zealand, known for its stability. As a stable currency in the South Pacific, the Dollar reflects New Zealand’s economic identity. The Reserve Bank of New Zealand manages the currency, focusing on maintaining price stability and supporting overall economic well-being.",
  PHP: "The Philippine Peso (PHP) is the official currency of the Southeast Asian archipelago. The Peso reflects the Philippines’ economic activities, cultural diversity, and regional significance. The Bangko Sentral ng Pilipinas manages the Peso, aiming to ensure financial stability and support economic development.",
  SGD: "The Singapore Dollar (SGD) is used in the thriving financial hub of Singapore. As an international financial center, the Dollar plays a crucial role in Singapore’s economic landscape. The Monetary Authority of Singapore manages the currency, focusing on maintaining price stability and supporting overall economic well-being.",
  THB: "The Thai Baht (THB) is the official currency of Thailand, indicative of its dynamic emerging market. The Baht reflects Thailand’s economic growth, cultural richness, and regional influence. The Bank of Thailand manages the currency, aiming to ensure financial stability and support sustainable economic development.",
  ZAR: "The South African Rand (ZAR) is the official currency of South Africa, representing a diverse African economy. The Rand reflects South Africa’s economic activities, cultural diversity, and regional significance. The South African Reserve Bank manages the currency, focusing on maintaining price stability and supporting overall economic well-being.",
};
export const sloganArray = [
  "Milk your investments",
  "The grass is greener in other currencies",
  "Teach a man to COW and you financed him for a lifetime",
  'Move your "live"stock for sustainability',
  "Have you heard about the tragedy of the commons? Protect our pastures!",
  "MUUsic to your wallet",
  "Money is not just in THAT type of grass",
  "Money doesn't grow on trees it grows on lush green fields",
  "I don't see you smile. You need some cheeese!",
  "We are the MOOsicians of finances",
  "Those rates are MOOving!",
  "Why don’t cows have any money? Because farmers milk them dry.",
  "How did the beef make its fortune? It “brisket” all!",
  "What do you call a cow that can solve complex math problems? A “steak”holder!",
  "We wish us all a bullish market!",
  "Make loud noises by yelling, banging pots and pans to keep the bear market away!",
  "If the bear market comes don't play dead, buy!",
  "Investing money have never been so aMOOsing.",
];
export const disclaimer =
  "The information provided on this financial site regarding currency exchange rates is intended for informational purposes only. While we strive to ensure the accuracy and timeliness of the data presented, we cannot guarantee its absolute correctness or completeness. Users are advised to independently verify all exchange rates and related information before making any financial decisions or transactions. Fluctuations in currency exchange rates are common and may occur rapidly due to various factors, including but not limited to economic conditions, geopolitical events, and market sentiment. This site does not provide investment, financial, or legal advice. Users should consult with qualified professionals or financial advisors before engaging in any currency exchange transactions or investment activities. We do not endorse or promote any specific currency exchange services, products, or platforms mentioned on this site. Users are solely responsible for their decisions and actions based on the information provided herein. By using this site, you agree to release us from any liability for the accuracy, completeness, or reliability of the information presented, as well as any consequences resulting from your use of such information. We reserve the right to modify, update, or remove content at any time without prior notice. Your use of this site constitutes your acceptance of these terms and conditions. If you do not agree with any part of this disclaimer, please refrain from using this site.";
